import React from "react"
import Layout from "../components/layout"
import Title from "../components/title"
import { graphql } from 'gatsby'
import Article from "../components/article"
import * as styles from './presentations.module.scss'

const Presentations = () => {
  return (
    <Layout>
      <div></div>
    </Layout>
  )
  }
  
/*const Presentations = ({data}) => {
  return (
    <Layout>
      <Title text='Presentations'></Title>
      <div>
        {data.allMarkdownRemark.edges.map(({node}) => (
          <div className={styles.presentation}>
            <div className={styles.conferenceName}>node.frontmatter.talkname</div>
            <div className={styles.conferenceLocation}>Some Location</div>
            <div className={styles.description} dangerouslySetInnerHTML={{__html: node.html}}/>
          </div>
        
        ))}
      </div>
    </Layout>
    )
}

export const query = graphql`
query {
  allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/presentations/"}},
      sort: {fields: [frontmatter___date], order: DESC}) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMM YYYY")
          talkName
        }
        html
      }
    }
  }
}`
*/

export default Presentations
